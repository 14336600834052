<template>
  <!--v-if="user && user.partnerID === '5e39227d1dd9b10d0bd42895'" -->
  <v-flex v-if="user && user.partnerID === '5e39227d1dd9b10d0bd42895'" xs12>
    <v-card flat :loading="gettingPatients">
      <v-card-title>
        Add Data to demo patients
      </v-card-title>

      <v-simple-table style="width: 100%;">
        <template v-slot:default>
          <tbody>
            <tr v-for="(item, index) in enrolledPatients" :key="index">
              <td>
                {{ item.fullName }}
                <span style="opacity:0.5" v-if="item.latestEvents && item.latestEvents.MEASUREMENT_BLOOD_PRESSURE">
                  | last BP
                  {{ relativeTime(item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.timestamp) }}</span
                >
              </td>
              <td style="text-align: right">
                <v-btn depressed color="primary" @click="bringBPUpToDate(item)" class="ma-2">BP up to date</v-btn>
                <v-btn depressed @click="addTodayData(item)" class="ma-2">BP Now</v-btn>
                <v-btn depressed @click="addDataYesterday(item)" class="ma-2">BP yesterday</v-btn>
                <v-btn depressed @click="addData3DaysAgo(item)" class="ma-2">BP 3 days ago</v-btn>
                <v-btn depressed @click="add30DaysOfData(item)" class="ma-2">BP for 30 days</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import moment from 'moment-timezone'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      password: 'password',
      changeDetected: false,
      gettingPatients: false,
    }
  },
  components: {},
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('dashboard', ['enrolledPatients', 'counts', 'userPatients']),
    patientsWithActualConnectedDevices() {
      return this.enrolledPatients.filter(function(e) {
        return e.deviceAssigned
      })
    },
  },
  watch: {
    enrolledPatients() {
      this.gettingPatients = false
    },
  },
  methods: {
    ...mapActions('auth', ['updateUserObject']),
    ...mapActions('dashboard', ['getEnrolledPatients', 'getUserPatients']),
    ...mapActions('patient', ['postSomeBP', 'upToDateBP']),
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    bringBPUpToDate(patient) {
      console.log(patient)
      if (patient.latestEvents && patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE) {
        let difference = this.daysSinceTimestamp(patient.latestEvents.MEASUREMENT_BLOOD_PRESSURE.timestamp / 1000)
        console.log(difference)
        if (difference > 0) {
          this.upToDateBP({ id: patient._id, daysBack: difference })
        }
      } else {
        this.add30DaysOfData(patient)
      }
    },
    add30DaysOfData(patient) {
      console.log(patient)
      this.postSomeBP({ diff: 2, id: patient._id })
    },
    addTodayData(patient) {
      this.postSomeBP({ diff: 1, id: patient._id })
    },
    add3DaysOfData(patient) {
      console.log(patient)
    },
    addData3DaysAgo(patient) {
      console.log(patient)
      this.postSomeBP({ diff: 3, id: patient._id })
    },
    addDataYesterday(patient) {
      console.log(patient)
      this.postSomeBP({ diff: 4, id: patient._id })
    },
    saveUpdates() {
      if (this.changeDetected) {
        this.updateUserObject().then(() => {
          this.changeDetected = false
          bus.$emit('toast', { type: 'success', text: 'Account Updated' })
        })
      }
    },
  },
  mounted() {
    this.getEnrolledPatients()
  },
}
</script>

<style></style>
